import React from 'react';
import './Navbar.css';

import PCViewNavbar from './PCViewNavbar/PCViewNavbar';
import MobileViewNavbar from './MobileViewNavbar/MobileViewNavbar';

function Navbar({ data_lang, updateLang }) {
    return (
        <nav className='Navbar'>
            {/* COMPUTER VIEWPORT */}
            <div className={`PCViewNavbarContainer`}>
                <PCViewNavbar data_lang={data_lang} updateLang={updateLang} />
            </div>

            {/* MOBILE VIEWPORT */}
            <div className={`MobileViewNavbarContainer`}>
                <MobileViewNavbar data_lang={data_lang} updateLang={updateLang} />
            </div>
        </nav>
    );
}

export default Navbar;