// import React, { useLayoutEffect, useState } from 'react';
import React from 'react';
import './Intro.css';
// const bg1 = '/assets/images/bg1.png';
// const bg2 = '/assets/images/bg2.png';
// const bg3 = '/assets/images/bg3.png';
// const bg4 = '/assets/images/bg4.png';
// const bg5 = '/assets/images/bg5.png';
const logo = '/assets/images/logo.png';
// const arrowR = '/assets/images/icons/arrow_right.svg';
// const arrowL = '/assets/images/icons/arrow_left.svg';
// let arrOfBgs = [bg1, bg2, bg3, bg4, bg5];
// var bgIndex = 0;

function Intro({ data_lang }) {
    // const [currentBG, setCurrentBG] = useState(arrOfBgs[bgIndex]);

    // useLayoutEffect(() => {
    //     updateBg();
    // }, []);

    // function updateBg() {
    //     setInterval(() => {
    //         incrementBgIndex();
    //     }, 6000);
    // }

    // const incrementBgIndex = () => {
    //     if (bgIndex === (arrOfBgs.length - 1))
    //         bgIndex = 0;
    //     else
    //         bgIndex++;
    //     return setCurrentBG(arrOfBgs[bgIndex]);
    // }

    // const decrementBgIndex = () => {
    //     if (bgIndex === 0)
    //         bgIndex = (arrOfBgs.length - 1);
    //     else
    //         bgIndex = bgIndex - 1;
    //     return setCurrentBG(arrOfBgs[bgIndex]);
    // }

    return (
        <div className='Intro'>
            {/* <div className='Intro' style={{ backgroundImage: `url(${currentBG})` }}>
            <button id="bg__arrow_left" onClick={decrementBgIndex}>
                <img src={arrowL} alt="Arrow left" />
            </button>
            <button id="bg__arrow_right" onClick={incrementBgIndex} >
                <img src={arrowR} alt="Arrow right" />
            </button> */}

            <div id="intro__content">
                <img src={logo} alt="Logo" id="logo" />
                <a href="/#stanze" id="btn__toStanze">
                    {data_lang.INTRO_SCOPRI_STANZE}
                </a>
            </div>
        </div>
    );
}

export default Intro;