import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './assets/css/index.css';
import langData from './assets/utils/languagesData.json';
import { getCookie } from './assets/utils/getCookie';

import Navbar from './Components/Navbar/Navbar';
import Intro from './Components/Intro/Intro';
import Ecoturismo from './Components/Ecoturismo/Ecoturismo';
import Rooms from './Components/Rooms/Rooms';
import GoBookings from './Components/GoBookings/GoBookings';
import Gallery from './Components/Gallery/Gallery';
import Contacts from './Components/Contacts/Contacts';
import Footer from './Components/Footer/Footer';
import Notification from './Components/Notification/Notification';
import PrivacyNCookies from './Components/PrivacynCookies/PrivacyNCookies';
import NotFound from './Components/NotFound/404';

function App() {
  const [langObj, setLangObj] = useState({});
  const [currentLang, setCurrentLang] = useState('it_IT');

  useEffect(() => {
    if (!getCookie("lang")) {
      document.cookie = `lang=it_IT; max-age=384000; path=/;`;
    }
    else {
      setCurrentLang(getCookie("lang"));
    }

    if (currentLang === 'it_IT') {
      return setLangObj(langData.it_IT);
    }
    if (currentLang === 'en_EN') {
      return setLangObj(langData.en_EN);
    }
    if (currentLang === 'de_DE') {
      return setLangObj(langData.de_DE);
    }
    if (currentLang === 'es_ES') {
      return setLangObj(langData.es_ES);
    }
  }, [currentLang]);

  return (
    <Router>
      <Navbar data_lang={langObj} updateLang={setCurrentLang} />
      <Routes>
        <Route path='/' exact element={
          <div className="App">
            <Intro data_lang={langObj} />
            <Ecoturismo data_lang={langObj} />
            <Rooms data_lang={langObj} />
            <GoBookings data_lang={langObj} />
            <Contacts data_lang={langObj} />
          </div>
        } />
        <Route path='/galleria' exact element={<Gallery data_lang={langObj} />} />
        <Route path='/privacy' exact element={<PrivacyNCookies data_lang={langObj} />} />
        <Route path='*' element={<NotFound data_lang={langObj} />} />
      </Routes>
      <Notification data_lang={langObj} />
      <Footer data_lang={langObj} />
    </Router>

  )
}

export default App
