import React, { useState, useEffect } from 'react';
import './Gallery.css';
import Modal from '../Modal/Modal';
import Helmet from 'react-helmet';
import { CloudinaryContext } from 'cloudinary-react';
import axios from 'axios';

function Gallery({ data_lang }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState();
    const [category, setCategory] = useState("all");
    const [resources, setResources] = useState(null);

    function openModal(img) {
        setIsModalOpen(true);
        setModalImg(img);
    }

    function updateCtg(tag) {
        setCategory(tag);
        getImagesTag(tag)
    }

    function getImagesTag(tag) {
        axios({
            method: 'GET',
            url: `http://res.cloudinary.com/${process.env.REACT_APP_cldName}/image/list/${tag}.json`,

        }).then(res => {
            return setResources(res.data.resources);
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        getImagesTag("all");
    }, []);

    return (
        <div className='Gallery'>
            <Helmet>
                <title>Via Roma 12 Verona - {data_lang.NAVBAR_GALLERIA ? data_lang.NAVBAR_GALLERIA : ""}</title>
                <meta name="description" content={data_lang.INTRO_SCOPRI_STANZE ? data_lang.INTRO_SCOPRI_STANZE : ""} />
                <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
            </Helmet>
            {isModalOpen && <Modal image={modalImg} closeModal={() => setIsModalOpen(false)} />}
            <h1>{data_lang.GALLERIA_TITOLO}</h1>
            <div id="TagSelector">
                <button className={`btn__tagselector ${category === "all" ? `btn__tagselector_active` : ``}`} onClick={() => updateCtg("all")}>{data_lang.TAG_TUTTE}</button>
                <button className={`btn__tagselector ${category === "stanza" ? `btn__tagselector_active` : ``}`} onClick={() => updateCtg("stanza")}>{data_lang.TAG_STANZE}</button>
                <button className={`btn__tagselector ${category === "verona" ? `btn__tagselector_active` : ``}`} onClick={() => updateCtg("verona")}>{data_lang.TAG_VERONA}</button>
                <button className={`btn__tagselector ${category === "orchidea" ? `btn__tagselector_active` : ``}`} onClick={() => updateCtg("orchidea")}>{data_lang.TAG_ORCHIDEA}</button>
                <button className={`btn__tagselector ${category === "zaffiro" ? `btn__tagselector_active` : ``}`} onClick={() => updateCtg("zaffiro")}>{data_lang.TAG_ZAFFIRO}</button>
                <button className={`btn__tagselector ${category === "ametista" ? `btn__tagselector_active` : ``}`} onClick={() => updateCtg("ametista")}>{data_lang.TAG_AMETISTA}</button>
                <p id="tags_results">{resources && resources.length} {data_lang.P_RISULTATI}</p>
            </div>
            <div id="gallery-container">
                {
                    resources && resources.map((image, key) => (
                        <CloudinaryContext cloudName={process.env.REACT_APP_cldName}>
                            <img
                                key={key}
                                src={`https://res.cloudinary.com/${process.env.REACT_APP_cldName}/image/upload/v1647789334/${image.public_id}.${image.format}`}
                                alt="Verona & Stanze"
                                className='gallery-image'
                                onClick={() => openModal(`https://res.cloudinary.com/${process.env.REACT_APP_cldName}/image/upload/v1647789334/${image.public_id}.${image.format}`)}
                            />
                        </CloudinaryContext>
                    ))
                }

            </div>
        </div>
    );
}

export default Gallery;