import React, { useState } from 'react';
import './Ecoturismo.css';
import Servizio from './Servizio';
import PageTitle from '../PageTitle/PageTitle';
import Modal from '../Modal/Modal';
import Helmet from 'react-helmet';
// ICONS
import wifi_ico from '../../assets/images/icons/wifi.svg';
import plane_ico from '../../assets/images/icons/plane.svg';
import cold_ico from '../../assets/images/icons/cold.svg';
import train_ico from '../../assets/images/icons/train.svg';
import cig_ico from '../../assets/images/icons/smokefree.svg';
import parking_ico from '../../assets/images/icons/parking.svg';
import zoom_hover from '../../assets/images/icons/zoom.svg';
import verona4 from '../../assets/images/verona/verona_4.jpg';

function Ecoturismo({ data_lang }) {
    const [modalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState();

    function openModal(img) {
        setIsModalOpen(true);
        setModalImg(img);
    }

    return (
        <div className='Ecoturismo' id="ecoturismo">
            <Helmet>
                <title>Via Roma 12 Verona - {data_lang.ECO_DESC_1 ? data_lang.ECO_DESC_1 : ""}</title>
                <meta name="description" content={data_lang.ECO_DESC_1 ? data_lang.ECO_DESC_1 : ""} />
            </Helmet>
            {modalOpen && <Modal image={modalImg} closeModal={() => setIsModalOpen(false)} />}
            <div id="eco__left">
                {/* <h1>SOGGIORNA NEL CUORE DI VERONA</h1> */}
                <PageTitle label={data_lang.ECO_TITOLO} />
                <div id="eco__left_body">
                    <p>
                        {/* Situato a Verona, Via Roma 12 Verona offre camere climatizzate con connessione WiFi grauita.
                        La struttura dista circa 200 metri dal Museo di Castelvecchio, 300 metri dall'Arena di Verona e da Via Mazzini. */}
                        {data_lang.ECO_DESC_1}
                    </p>
                    <p>
                        {/* Sono presenti due parchessi pubblici coperti a pagamento a pochi centinaia di metri dalla struttura. La struttura e' non fumatori.
                        Il Via Roma 12 Verona dista 700 metri da Piazza delle Erbe ed 1 km dalla Basilica di San Zeno Maggiore. L'aereoporto piu' vicino e' quello di Verona a 15 km.
                        Dista 1.2 km dalla stazione ferroviaria. */}
                        {data_lang.ECO_DESC_2}
                    </p>
                    <div id="eco__left_services">
                        <Servizio icon={wifi_ico} label={data_lang.ECO_WIFI} />
                        <Servizio icon={plane_ico} label={data_lang.ECO_AEREOPORTO} label2={data_lang.ECO_AEREOPORTO_DIST} />
                        <Servizio icon={cold_ico} label={data_lang.ECO_AIR} />
                        <Servizio icon={train_ico} label={data_lang.ECO_TRENO} label2={data_lang.ECO_TRENO_DIST} />
                        <Servizio icon={cig_ico} label={data_lang.ECO_NOFUMATORI} />
                        <Servizio icon={parking_ico} label={data_lang.ECO_PARCHEGGI} />
                    </div>
                </div>
            </div>
            <div id="eco__right">
                <div id="eco__right_verona" onClick={() => openModal(verona4)}>
                    <div id="zoom_hover">
                        <img src={zoom_hover} alt="Arena di Verona" />
                        <p id="zoom_label">{data_lang.zoom_text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ecoturismo;