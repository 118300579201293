import React from 'react';
import './GoBookings.css';

function GoBookings({ data_lang }) {
    return (
        <div className='GoBookings'>
            <h1>{data_lang.COSA_ASPETTI}</h1>
            <a
                href='http://www.booking.com/Share-XMZuDBV'
                target="_blank"
                rel="noopener noreferrer"
            >{data_lang.PRENOTA_BTN}</a>
        </div>
    );
}

export default GoBookings;