import React from 'react';
import './Footer.css';
import logo from '../../assets/images/logo.png';

function Footer({ data_lang }) {
    return (
        <div className='Footer'>
            <div className="footer__box footer__logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className='footer__divider'></div>
            <div className="footer__box footer__links">
                <h3>{data_lang.FOOTER_NAVIGA}</h3>
                <div>
                    <a href="/#stanze">{data_lang.FOOTER_STANZE}</a>
                    <a href="/#ecoturismo">{data_lang.FOOTER_ECOTURISMO}</a>
                    <a href="/galleria">{data_lang.FOOTER_GALLERIA}</a>
                    <a
                        href='http://www.booking.com/Share-XMZuDBV'
                        target="_blank"
                        rel="noopener noreferrer"
                    >{data_lang.BTN_BOOKINGS}</a>
                </div>
            </div>
            <div className='footer__divider'></div>
            <div className="footer__box footer__contacts">
                <h3>{data_lang.FOOTER_CONTATTI}</h3>
                <div id="footer__contacts_box">
                    <div id="footer__contacts_indirizzo">
                        {data_lang.INDIRIZZO_TITOLO}<br />
                        {data_lang.INDIRIZZO_DESC_1}<br />
                        {data_lang.INDIRIZZO_DESC_2}<br />
                        <a
                            href='https://maps.google.com/maps/dir//Via+Roma,+12+37121+Verona+VR+Italy/@45.438841,10.9900265,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x477f5f4511b5ed9b:0x56f37a9d039a7dba'
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {data_lang.MAPS_DESC}
                        </a>
                    </div>
                    <div id="footer__contacts_media">
                        {data_lang.EMAIL_TITOLO}<br />
                        {data_lang.EMAIL_DESC}<br /><br />
                        {data_lang.NUMERO_TITOLO}<br />
                        {data_lang.NUMERO_DESC}
                    </div>
                </div>
            </div>
            <div className='footer__divider'></div>
            <div className="footer__box footer__privacy">
                <h3>{data_lang.FOOTER_POLICY}</h3>
                <div>
                    <a href='/privacy'>{data_lang.FOOTER_PRIVACY}</a>
                    <a href='/privacy#cookies'>{data_lang.FOOTER_COOKIES}</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;