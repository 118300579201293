import React from 'react';
import './Modal.css';
import close_ico from '../../assets/images/icons/close.svg';

function Modal({ image, closeModal }) {
    return (
        <div className='Modal' onClick={closeModal}>
            <button id="modal__close" onClick={closeModal}>
                <img src={close_ico} alt="Close icon" />
            </button>
            <img src={image} className='modal__content' alt="Zoomed in" />
        </div>
    );
}

export default Modal;