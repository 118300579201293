import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './PCViewNavbar.css';

// ICONS
const NavLogo = '/assets/images/NavLogo.png';
const itaFlag = '/assets/images/icons/it_IT.png';
const ukFlag = '/assets/images/icons/en_EN.png';
const spainFlag = '/assets/images/icons/es_ES.png';
const gerFlag = '/assets/images/icons/de_DE.png';

function PCViewNavbar({ data_lang, updateLang }) {
    const [isLangOpen, setIsLangOpen] = useState(false);
    const [otherFlags, setOtherFlags] = useState([]);
    const [currentFlag, setCurrentFlag] = useState();

    const [yIndex, setYIndex] = useState(0);
    const [y, setY] = useState(window.scrollY);
    const [isNavVisibile, setIsNavVisibile] = useState(true);
    const [isNavDark, setIsNavDark] = useState(false);

    let btn__currentLang = document.getElementById('btn__currentLang');
    let btn__otherLang = document.getElementById('otherLangs');
    let navigate = useNavigate();

    const handleNavigation = useCallback(
        e => {
            setIsLangOpen(false);
            const window = e.currentTarget;
            if (y > window.scrollY) {
                setIsNavVisibile(true);
                if (y < 20) {
                    setIsNavDark(false);
                }
            } else if (y < window.scrollY) {
                setYIndex(yIndex + 1);
                if (y >= 20) {
                    setIsNavDark(true);
                    setIsNavVisibile(false);
                }
                if (yIndex > 40) {
                    setIsNavVisibile(false);
                    setYIndex(0);
                }
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    useLayoutEffect(() => {
        if (data_lang.lang === 'it_IT') {
            setCurrentFlag(itaFlag);
            setOtherFlags([ukFlag, spainFlag, gerFlag]);
        }
        if (data_lang.lang === 'en_EN') {
            setCurrentFlag(ukFlag);
            setOtherFlags([itaFlag, spainFlag, gerFlag]);
        }
        if (data_lang.lang === 'de_DE') {
            setCurrentFlag(gerFlag);
            setOtherFlags([itaFlag, spainFlag, ukFlag]);
        }
        if (data_lang.lang === "es_ES") {
            setCurrentFlag(spainFlag);
            setOtherFlags([itaFlag, ukFlag, gerFlag]);
        }
    }, [data_lang]);

    function handleLangClick(lang) {
        let a = lang.split("/").pop().split(".")[0];
        document.cookie = `lang=${a}; max-age=384000; path=/;`;
        updateLang(a);
        setIsLangOpen(false);
    }

    function openMenu() {
        if (isLangOpen === false) {
            btn__currentLang.focus();
            return setIsLangOpen(true);
        }
        if (isLangOpen === true) {
            btn__currentLang.blur();
            return setIsLangOpen(false);
        }
    }

    if (btn__currentLang && btn__otherLang) {
        btn__currentLang.addEventListener('focusout', () => {
            setTimeout(() => {
                return setIsLangOpen(false);
            }, 100);
        });
    }

    return (
        <div className={`PCViewNavbar ${isNavDark ? `Navbar__Dark` : ``} ${isNavVisibile ? `` : `Navbar__Hide`}`}>
            <a id="nav__logo" onClick={() => { navigate('/'); window.scrollTo(0, 0) }}>
                <img src={NavLogo} alt="Navbar Logo" />
            </a>
            <a href="/#stanze">
                {data_lang.NAVBAR_STANZE}
            </a>
            <a href="/#ecoturismo">
                {data_lang.NAVBAR_ECOTURISMO}
            </a>
            <a href='/galleria'>
                {data_lang.NAVBAR_GALLERIA}
            </a>
            <a href='/#contatti'>
                {data_lang.NAVBAR_CONTATTI}
            </a>
            <div id="langSelect">
                <button id="btn__currentLang" onClick={() => openMenu()}><img src={currentFlag} alt="Language Flag" /></button>
                {
                    isLangOpen ? <div id="otherLangs">
                        {
                            otherFlags.map((lang, key) => (
                                <button
                                    key={key}
                                    className="btn__Flag"
                                    onClick={() => handleLangClick(lang)}>
                                    <img src={lang} alt="Language flag" />
                                </button>
                            ))
                        }
                    </div> : null
                }
            </div>
        </div>
    );
}

export default PCViewNavbar;