import React from 'react';
import './PageTitle.css';

function PageTitle({ label, bg }) {
    return (
        <div className={`PageTitle`}>
            <div id='header-line'></div>
            {/* <h1 className={`${bg === "dark" ? `pageTitle_dark` : `pageTitle_light`}`}>{label}</h1> */}
            <h1>{label}</h1>
        </div>
    );
}

export default PageTitle;