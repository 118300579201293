import React, { useState } from 'react';
import './MobileViewNavbar.css';

// ICONS
const NavLogo = '/assets/images/NavLogo.png';
const itaFlag = '/assets/images/icons/it_IT.png';
const ukFlag = '/assets/images/icons/en_EN.png';
const spainFlag = '/assets/images/icons/es_ES.png';
const gerFlag = '/assets/images/icons/de_DE.png';
var flags = [itaFlag, ukFlag, spainFlag, gerFlag];

function MobileViewNavbar({ data_lang, updateLang }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    function handleLangClick(lang) {
        let a = lang.split("/").pop().split(".")[0];
        document.cookie = `lang=${a}; max-age=384000; path=/;`;
        updateLang(a);
        setIsMenuOpen(false);
    }

    return (
        <div className={`MobileViewNavbar ${isMenuOpen ? `MobileViewNavbar_display` : ``}`}>
            <button className={`navbar__hamburger `} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <div id="hamburger_stick1"></div>
                <div id="hamburger_stick2"></div>
            </button>

            <div className={`MobileViewNavbar__content ${isMenuOpen ? `MobileViewNavbar__content_display` : ``}`}>
                <a href="/" id="MobileViewNavbar_logo" onClick={() => setIsMenuOpen(false)}>
                    <img src={NavLogo} alt="Navbar Logo" />
                </a>
                <a href='/#stanze' onClick={() => setIsMenuOpen(false)}>
                    {data_lang.NAVBAR_STANZE}
                </a>
                <a href='/#ecoturismo' onClick={() => setIsMenuOpen(false)} >
                    {data_lang.NAVBAR_ECOTURISMO}
                </a>
                <a href='/galleria' onClick={() => setIsMenuOpen(false)} >
                    {data_lang.NAVBAR_GALLERIA}
                </a>
                <a href='/#contatti' onClick={() => setIsMenuOpen(false)} >
                    {data_lang.NAVBAR_CONTATTI}
                </a>
                <div id="MobileViewNavbar__langSelect">
                    {flags.map((lang, key) => (
                        <button
                            key={key}
                            className="MobileViewNavbar__btn__Flag"
                            onClick={() => handleLangClick(lang)}>
                            <img src={lang} alt="Language flag" />
                        </button>
                    ))}
                </div>

            </div>
        </div>
    );
}

export default MobileViewNavbar;