import React from 'react';
import {hydrate, render} from 'react-dom';
import './assets/css/index.css';
import App from './App';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes())
  hydrate(<App />, rootElement);
else
  render(<App />, rootElement);