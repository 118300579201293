import React from 'react';
import './404.css';
import Helmet from 'react-helmet';

function NotFound({ data_lang }) {
    return (
        <div className='NotFound'>
            <Helmet>
                <title>Via Roma 12 Verona - 404</title>
            </Helmet>
            <div id="notfound-info">
                {data_lang.NotFound_title}<br />
                <a href="/">{data_lang.NotFound_link}</a>
            </div>
        </div>
    );
}

export default NotFound;