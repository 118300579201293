import React, { useState } from 'react';
import './Rooms.css';
import PageTitle from '../PageTitle/PageTitle';
import Modal from '../Modal/Modal';

const orchidea_1 = '/assets/images/stanze/OrchideaBianca1.png';
const orchidea_2 = '/assets/images/stanze/OrchideaBianca2.png';
const orchidea_3 = '/assets/images/stanze/OrchideaBianca3.png';
const zaffiro_1 = '/assets/images/stanze/Zaffiro1.png';
const zaffiro_2 = '/assets/images/stanze/Zaffiro2.png';
const zaffiro_3 = '/assets/images/stanze/Zaffiro3.png';
const ametista_1 = '/assets/images/stanze/Ametista1.png';
const ametista_2 = '/assets/images/stanze/Ametista2.png';
const ametista_3 = '/assets/images/stanze/Ametista3.png';

function Rooms({ data_lang }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImg, setModalImg] = useState();

    function openModal(img) {
        setIsModalOpen(true);
        setModalImg(img);
    }

    return (
        <div className='Rooms' id="stanze">
            {isModalOpen && <Modal image={modalImg} closeModal={() => setIsModalOpen(false)} />}
            <PageTitle label={data_lang.STANZE_TITOLO} />
            <div id="orchidea_bianca">
                <div className="room_desc">
                    <h1>ORCHIDEA BIANCA</h1>
                    <p className="room_desc_1">{data_lang.STANZA_ORCHIDEA_DESC_1}</p>

                    {data_lang.STANZA_ORCHIDEA_DESC_2}
                    <ol className='room_services'>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO1}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO2}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO3}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO4}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO5}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO6}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO7}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO8}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO9}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO10}</li>
                    </ol>
                    <p>{data_lang.STANZA_ORCHIDEA_P}</p>
                </div>
                <div className="room_images">
                    <div className="room_img_1">
                        <img src={orchidea_1} onClick={() => openModal(orchidea_1)} alt="Stanza Orchidea 1" />
                    </div>
                    <div className="room_img_2">
                        <img src={orchidea_2} onClick={() => openModal(orchidea_2)} alt="Stanza Orchidea 2" />
                    </div>
                    <div className="room_img_3">
                        <img src={orchidea_3} onClick={() => openModal(orchidea_3)} alt="Stanza Orchidea 3" />
                    </div>
                    <a href='/galleria'>{data_lang.visualizza_foto}</a>
                </div>
            </div>
            <div id="zaffiro">
                <div className="room_images r_img_zaffiro">
                    <div className="room_img_1">
                        <img src={zaffiro_1} onClick={() => openModal(zaffiro_1)} alt="Stanza Zaffiro 1" />
                    </div>
                    <div className="room_img_2">
                        <img src={zaffiro_2} onClick={() => openModal(zaffiro_2)} alt="Stanza Zaffiro 2" />
                    </div>
                    <div className="room_img_3">
                        <img src={zaffiro_3} onClick={() => openModal(zaffiro_3)} alt="Stanza Zaffiro 3" />
                    </div>
                    <a href='/galleria'>{data_lang.visualizza_foto}</a>
                </div>
                <div className="room_desc">
                    <h1>ZAFFIRO</h1>
                    <p className="room_desc_1">{data_lang.STANZA_ZAFFIRO_DESC_1}</p>

                    {data_lang.STANZA_ORCHIDEA_DESC_2}
                    <ol className='room_services'>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO1}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO2}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO3}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO4}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO5}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO6}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO7}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO8}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO9}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO10}</li>
                    </ol>
                </div>
            </div>
            <div id="ametista">
                <div className="room_desc">
                    <h1>AMETISTA</h1>
                    <p className="room_desc_1">{data_lang.STANZA_ZAFFIRO_DESC_1}</p>

                    {data_lang.STANZA_ORCHIDEA_DESC_2}
                    <ol className='room_services'>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO1}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO2}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO3}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO4}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO5}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO6}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO7}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO8}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO9}</li>
                        <li>{data_lang.STANZA_ORCHIDEA_SERVIZIO10}</li>
                    </ol>
                </div>
                <div className="room_images">
                    <div className="room_img_1">
                        <img src={ametista_1} onClick={() => openModal(ametista_1)} alt="Stanza Ametista 1" />
                    </div>
                    <div className="room_img_2">
                        <img src={ametista_2} onClick={() => openModal(ametista_2)} alt="Stanza Ametista 2" />
                    </div>
                    <div className="room_img_3">
                        <img src={ametista_3} onClick={() => openModal(ametista_3)} alt="Stanza Ametista 3" />
                    </div>
                    <a href='/galleria'>{data_lang.visualizza_foto}</a>
                </div>
            </div>
        </div>
    );
}

export default Rooms;