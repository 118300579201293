import React from 'react';
import './Contacts.css';
import PageTitle from '../PageTitle/PageTitle';
// ICONS
import whatsapp_ico from '../../assets/images/icons/whatsapp.svg';
import phone_ico from '../../assets/images/icons/phone.svg';
import email_ico from '../../assets/images/icons/email.svg';
import bookings_ico from '../../assets/images/icons/bookings.svg';

function Contacts({ data_lang }) {
    return (
        <div className='Contacts' id="contatti">
            <PageTitle label={data_lang.CONTATTI_TITOLO} />
            <p>{data_lang.CONTATTI_DESC}</p>

            <div id="contact__boxes">
                <div className='contact__box c__box_left'>
                    <p>
                        {data_lang.INDIRIZZO_TITOLO}<br />
                        {data_lang.INDIRIZZO_DESC_1}<br />
                        {data_lang.INDIRIZZO_DESC_2}
                    </p>
                    <p>
                        {data_lang.NUMERO_TITOLO}<br />
                        {data_lang.NUMERO_DESC}
                    </p>
                    <p>
                        {data_lang.EMAIL_TITOLO}<br />
                        {data_lang.EMAIL_DESC}
                    </p>
                </div>

                <div className='contact__box c__box_mid'>
                    <div id="contacts__btn_box">
                        <a
                            href='https://api.whatsapp.com/send/?phone=393482662834'
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ background: 'var(--whatsappColor)' }}
                        >
                            <img src={whatsapp_ico} alt="WhatsApp Icon" />
                            {data_lang.BTN_WHATSAPP}
                        </a>
                        <a
                            href='tel:+393482662834'
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ background: 'var(--callmeColor)' }}
                        >
                            <img src={phone_ico} alt="Telefono Icon" />
                            {data_lang.BTN_CHIAMA}
                        </a>
                        <a
                            href='mailto:marcodaniele22@gmail.com'
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ background: 'var(--emailmeColor)' }}
                        >
                            <img src={email_ico} alt="Email Icon" />
                            {data_lang.BTN_EMAIL}
                        </a>
                        <a
                            href='http://www.booking.com/Share-XMZuDBV'
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ background: 'var(--bookingsColor)' }}
                        >
                            <img src={bookings_ico} alt="Bookings.com Icon" />
                            {data_lang.BTN_BOOKINGS}
                        </a>
                    </div>
                </div>

                <div className='contact__box c__box_right'>
                    <h2>{data_lang.MAPS_TITOLO}</h2>
                    <iframe
                        title='Google Maps Indication'
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Via%20Roma%2012%20Verona+(Via%20Roma%2012%20Verona)&amp;t=k&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    />
                </div>
            </div>
        </div>
    );
}

export default Contacts;