import React from 'react';
import './Ecoturismo.css';

function Servizio({ icon, label, label2 }) {
    return (
        <div className='service'>
            <img src={icon} alt="Servizio icona" />
            {label}<br />
            {label2 ? label2 : null}
        </div>
    );
}

export default Servizio;