import React, { useState, useLayoutEffect } from 'react'
import './Notification.css';
import warn_ico from '../../assets/images/icons/warn.png';
import { getCookie } from '../../assets/utils/getCookie';

function Notification({ data_lang }) {
    const [isDismissed, setIsDismissed] = useState(false);

    useLayoutEffect(() => {
        if (!getCookie("notice_dismiss")) {
            setIsDismissed(false);
            document.cookie = `notice_dismiss=false; max-age=384000; path=/;`;
        }
        else {
            if (getCookie("notice_dismiss") === "true")
                setIsDismissed(true)
            else
                setIsDismissed(false);
        }
    }, [isDismissed]);

    function noticeConfirmed() {
        setIsDismissed(true);
        document.cookie = `notice_dismiss=true; max-age=384000; path=/;`;
    }

    return (
        isDismissed === false ? <div className='Notification'>
            <img src={warn_ico} alt="Warn Icon" />
            {data_lang.NOTIFICATION_TEXT}
            <button id="not_1" onClick={() => noticeConfirmed()}>
                ✖
            </button>
            <button id="not_2" onClick={() => noticeConfirmed()}>
                {data_lang.BTN_CONFERMA}
            </button>
        </div>
            : null
    );
}

export default Notification;